<template>
  <div>
    <vs-tabs class="invoice-list">
      <vs-tab label="Details">
        <div>
          <basic-users-detail
            fetchClinicsBy="superAdmin"
            :canDelete="true"
            :inTabEdit="true"
            :adminApprovalVar="true"
          />
        </div>
      </vs-tab>
      <!-- <vs-tab label="Organizations">
        <associated-organization-table :userId="basicUserId" />
      </vs-tab> -->
      <vs-tab label="Permissions" id="permissionsTab" v-if="staffIsNotOrgOwner">
          <Permissions :name="nurseData.firstName" :id="nurseData._id"/>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import basicUsersDetail from "../../components/basic-user/basicUsersDetail.vue";
import AssociatedClinics from "../../components/others/associatedClinics.vue";
import AssociatedOrganizationTable from "../../components/organization/associatedOrganizationTable.vue";
import Permissions from "../../components/org-owner/others/permissions.vue";
import { mapActions } from "vuex";

export default {
  components: {
    basicUsersDetail,
    AssociatedClinics,
    AssociatedOrganizationTable,
    Permissions
  },
  data: () => ({
    basicUserId: null,
    nurseData: {
      _id: null,
      firstName: "",
    },
  }),
  methods: {
    ...mapActions("nurse", ["fetchNurseDetails"]),
    async getNurseDetail(nurseId) {
      await this.fetchNurseDetails(nurseId).then((res) => {
        const nurse = res.data.data;
        this.nurseData = {
          _id: nurseId,
          firstName: nurse.firstName,
        };
      });
    },
  },
  async created() {
    this.basicUserId = this.$route.params.basicUserId;
    await this.getNurseDetail(this.basicUserId);
  },
  computed: {
    staffIsNotOrgOwner() {
      return (this.$store.state.AppActiveUser.userRole === "doctor" ||
             this.$store.state.AppActiveUser.userRole === "nurse"  ||
             this.$store.state.AppActiveUser.userRole === "basic") &&
             (localStorage.getItem( `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`) != 'Org Owner' ||
             this.$store.state.AppActiveUser.id != this.basicUserId)
    }
  }
};
</script>